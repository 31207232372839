import { createElement, FC } from 'react';
import { Redirect, useAnchor } from 'packages/react-nano-router';
import { ErrorBoundary as ReactErrorBoundary } from 'packages/react-error-boundary';
import { HttpClientErrorBoundary } from 'packages/http-client/react';

import { ErrorWithRetry } from './error-screens';
import { Errors, AuthError } from './errors';
import { LogoutLinks } from 'apps/acp/micro-frontends/logout';
import { DashboardLinks } from 'apps/acp/micro-frontends/dashboard';

/**
 * Blanket handler for any unknown rejection errors. This is where we'll
 * be handling any authentication related errors
 */
const handleUnknownRejectionError = (
  error: any,
  retry: () => void,
  logoutLink: LogoutLinks,
  acpVersion: string
) => {
  if (error instanceof AuthError) {
    /* capturing path along with queryparams
       I can not use `useLocation()` as i am regular javascript function
    */
    const path = `${window.location.pathname}${window.location.search}`;

    const redirectToUrl =
      error.error === 'expired'
        ? `${logoutLink.logoutWithPostAuthPathWithReason.url({
            reason: 'session-timeout',
            path: encodeURIComponent(path)
          })}`
        : `${logoutLink.logoutWithPostAuthPath.url({
            path: encodeURIComponent(path)
          })}`;
    return logoutLink ? (
      <Redirect to={redirectToUrl} />
    ) : (
      <ErrorWithRetry
        error={Errors.AUTH_EXPIRED}
        retry={retry}
        acpVersion={acpVersion}
      />
    );
  }
  return (
    <ErrorWithRetry
      error={Errors.UNKNOWN_REJECTION}
      retry={retry}
      acpVersion={acpVersion}
    />
  );
};

interface ErrorBoundaryProps {
  logoutLink: LogoutLinks;
  returnLink: DashboardLinks;
  acpVersion: string;
}

/**
 * The exported ErrorBoundary component, catches all network and application
 * errors thrown.
 */
export const ErrorBoundary: FC<ErrorBoundaryProps> = ({
  logoutLink,
  returnLink,
  acpVersion,
  children
}) => {
  const returnToHomeAnchor = useAnchor(returnLink.main);
  return (
    <ReactErrorBoundary
      renderError={(_, retry) => (
        <ErrorWithRetry
          error={Errors.UNKNOWN}
          retry={retry}
          returnLink={returnToHomeAnchor}
          acpVersion={acpVersion}
          type="JAVASCRIPT"
        />
      )}
    >
      <HttpClientErrorBoundary
        renderNetworkConnectivity={(_, retry, url) => (
          <ErrorWithRetry
            error={Errors.NETWORK_CONNECTION}
            retry={retry}
            acpVersion={acpVersion}
            webapiUrl={url}
          />
        )}
        renderNotOkFetchResponse={(_, retry, url, status) => (
          <ErrorWithRetry
            error={Errors.UNKNOWN_REJECTION}
            retry={retry}
            returnLink={returnToHomeAnchor}
            acpVersion={acpVersion}
            webapiUrl={url}
            statusCode={status.toString()}
          />
        )}
        renderServiceUnavailable={(_, retry, url, status) => (
          <ErrorWithRetry
            error={Errors.UNAVAILABLE}
            retry={retry}
            acpVersion={acpVersion}
            webapiUrl={url}
            statusCode={status.toString()}
          />
        )}
        renderUnknownRejectedFetchError={(error, retry) =>
          handleUnknownRejectionError(error, retry, logoutLink, acpVersion)
        }
      >
        {children}
      </HttpClientErrorBoundary>
    </ReactErrorBoundary>
  );
};
