export interface ErrorObject {
  title: string;
  body: string;
  CTA: string;
  returnLink?: string;
}

// TODO use <Content/> around these elements
// https://bitbucket.hq.netspend.com/projects/APP/repos/account-center-platform/pull-requests/6679/overview?commentId=105861
export const Errors: { [key: string]: ErrorObject } = {
  UNKNOWN: {
    title: 'Something went wrong',
    body: 'We were unable to load this page at this time.',
    CTA: 'Try Again',
    returnLink: 'Return Home'
  },
  UNAVAILABLE: {
    title: 'Service unavailable',
    body: 'Try again soon, we’re working on it.',
    CTA: 'Try Again'
  },
  NETWORK_CONNECTION: {
    title: 'Can’t connect',
    body:
      'We can’t find a network connection. Please check your connectivity and try again.',
    CTA: 'Try Again'
  },
  UNKNOWN_REJECTION: {
    title: 'Something went wrong',
    body: 'We were unable to load this page at this time.',
    CTA: 'Try Again',
    returnLink: 'Return Home'
  },
  AUTH_EXPIRED: {
    title: 'Your session has expired',
    body: 'For your security, we have logged you off.',
    CTA: 'Log Back In'
  }
};

export type AuthErrorType = 'expired' | 'invalid';

export class AuthError extends Error {
  constructor(
    public readonly error: AuthErrorType,
    public response?: Response
  ) {
    super('AcpAuthError');
  }
}
