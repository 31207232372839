import { createElement, FC } from 'react';
import { Anchor, Link, useAnchor } from 'packages/react-nano-router';
import { Title, Body } from 'legos/typography';
import { Button } from 'legos/button-legacy';
import { ContentBlock } from 'legos/content-block';

import { Illustration } from 'legos/illustration';
import { SuspenseHandler } from 'apps/acp/packages/react-suspense-handler';
import {
  ErrorBoundary as RawErrorBoundary,
  CenteredText
} from './styles/error-boundary.ccm.css';
import { ErrorObject } from './errors';
import { ButtonFlat } from 'legos/button/flat';
import { CenterChildren } from 'apps/acp/packages/center-children';
import { useHttpQuery } from 'packages/http-client/react';
import {
  ClientErrorLog,
  createClientErrorLogRequest
} from 'apps/acp/packages/webapi';

interface ErrorScreenProps {
  error: ErrorObject;
}

export const ErrorScreen: FC<ErrorScreenProps> = ({ error, children }) => (
  <RawErrorBoundary.div>
    <SuspenseHandler>
      <ContentBlock>
        <CenteredText.div>
          <Illustration
            type="warning"
            label="image illustration for a warning symbol"
          />
          <Title style={{ marginBottom: 8, marginTop: 16 }}>
            {error.title}
          </Title>
          <Body style={{ marginBottom: 32 }}>{error.body}</Body>
        </CenteredText.div>
        <CenterChildren>{children}</CenterChildren>
      </ContentBlock>
    </SuspenseHandler>
  </RawErrorBoundary.div>
);

interface ErrorWithRetryProps extends ErrorScreenProps {
  retry: () => void;
  acpVersion: string;
  returnLink?: Anchor;
  webapiUrl?: string;
  type?: 'API' | 'JAVASCRIPT';
  statusCode?: string;
}

export const ErrorLogger: FC<{ requestObj: ClientErrorLog }> = ({
  requestObj
}) => {
  useHttpQuery(createClientErrorLogRequest(requestObj));
  return null;
};

export const ErrorWithRetry: FC<ErrorWithRetryProps> = ({
  error,
  retry,
  returnLink,
  acpVersion,
  webapiUrl,
  statusCode = 'UNKNOWN STATUS',
  type = 'API'
}) => {
  const accessToken = sessionStorage.getItem('acp_access_token');
  const storedAuthBlocks = sessionStorage.getItem('acp_auth_blocks');
  const noAuthBlock =
    !storedAuthBlocks ||
    (storedAuthBlocks && JSON.parse(atob(storedAuthBlocks)).length === 0)
      ? true
      : false;

  const request: ClientErrorLog = {
    log_type: 'error',
    error_code: statusCode,
    payload: `client side error caused from ${type} on - ${location.href}, with error message ${error.title}: ${error.body}. UserAgent: ${navigator.userAgent}, webapiUrl: ${webapiUrl}`,
    acpVersion: acpVersion
  };

  return (
    <ErrorScreen error={error}>
      <ErrorLogger requestObj={request} />
      <Button variant="raised" onClick={retry}>
        {error.CTA}
      </Button>
      {noAuthBlock && accessToken && error.returnLink && (
        <ButtonFlat color="default" {...returnLink}>
          {error.returnLink}
        </ButtonFlat>
      )}
    </ErrorScreen>
  );
};

interface ErrorWithLinkProps extends ErrorScreenProps {
  returnLink: Link;
}

export const ErrorWithLink: FC<ErrorWithLinkProps> = ({
  error,
  returnLink
}) => {
  const returnLinkAnchor = useAnchor(returnLink);
  return (
    <ErrorScreen error={error}>
      <Button variant="raised" {...returnLinkAnchor}>
        {error.CTA}
      </Button>
    </ErrorScreen>
  );
};
