
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.ErrorBoundary_720cc61b73785dae{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.CenteredText_0280a4435e1552d4{text-align:center}`;
styleInject(_css)

/** ErrorBoundary Props */
export type ErrorBoundaryCCM = {
  /** ErrorBoundary Component Custom Properties */
  // No custom properties found

  /** ErrorBoundary Modifier Flags */
  // No modifiers classes found
};
/** Base ErrorBoundary component */
export const ErrorBoundary: ComponentCreator<ErrorBoundaryCCM> = createComponentCreator({
  "name": "ErrorBoundary",
  "base": "ErrorBoundary_720cc61b73785dae",
  "prop": {},
  "mod": {}
});


/** CenteredText Props */
export type CenteredTextCCM = {
  /** CenteredText Component Custom Properties */
  // No custom properties found

  /** CenteredText Modifier Flags */
  // No modifiers classes found
};
/** Base CenteredText component */
export const CenteredText: ComponentCreator<CenteredTextCCM> = createComponentCreator({
  "name": "CenteredText",
  "base": "CenteredText_0280a4435e1552d4",
  "prop": {},
  "mod": {}
});

